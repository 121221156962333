import React, { Component } from 'react';
import { flowRight, get } from 'lodash';
import type { ConnectedProps } from 'react-redux';
import type { WithTranslation } from '@wix/yoshi-flow-editor';
import {
  SECTION_CATEGORY,
  createPageUrl,
  getTagBySlug,
  isLayoutProGallery,
  type LayoutType,
  type PostAction,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import withComponents, {
  type WithComponents,
} from '../../../common/components/components-provider/with-components';
import { EmptyLayout } from '../../../common/components/layout';
import Page from '../../../common/components/page';
import { connect } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination';
import withAuth, { type WithAuth } from '../../../common/hoc/with-auth';
import withLayoutProps, { type WithLayoutProps } from '../../../common/hoc/with-layout-props';
import withPermissions, { type WithPermissions } from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import {
  isExcludePostContentSupported,
  isPostContentRequired,
} from '../../../common/selectors/layout-selectors';
import {
  getPostsByTagIdAndPage,
  getSortedPostsByTagId,
} from '../../../common/selectors/post-selectors';
import { getTagUrl } from '../../../common/services/get-tag-url';
import { handleNavigation } from '../../../common/services/navigation';
import {
  getIsMobile,
  isSite as isSiteSelector,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getIsTagLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsTagLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import type { NormalizedPost } from '../../../common/types';
import PostList from '../../components/post-list';
import type { RouteCategoryParams } from '../../constants/routes';
import withPaginationSettings, {
  type WithPaginationSettings,
} from '../../hoc/with-pagination-settings';

const Layout = EmptyLayout;

type OwnProps = {
  params: RouteCategoryParams;
  fetchTagPosts: () => void;
  setIsLoaded: (loaded: boolean) => void;
  page: number;
  layoutType: LayoutType;
  pageSize?: number;
  entityCount?: number;
  showPagination?: boolean;
  canSee: (action: PostAction, type: 'post', post: NormalizedPost) => boolean;
};

type Props = OwnProps &
  WithComponents &
  WithPermissions &
  WithPaginationSettings &
  WithLayoutProps &
  WithAuth &
  WithTranslation &
  ConnectedProps<typeof connector>;

export class TagsPage extends Component<Props> {
  state = {
    nextPage: 1,
  };

  componentWillUnmount() {
    this.props.setIsLoaded('tag', this.props.tagId, false);
  }

  componentDidUpdate({
    isAuthenticated,
    isBlocked,
    pageSize,
    excludeContent,
    tagId: prevTagId,
  }: Props) {
    const { tagId } = this.props;

    if (prevTagId !== tagId) {
      this.setState({ nextPage: 2 });
    }
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(tagId);
    }
  }

  fetchPosts(tagId?: string, page = this.props.page) {
    if (this.props.isBlocked || !tagId) {
      return Promise.resolve();
    }

    return this.props.fetchTagPosts({
      tagId,
      page,
    });
  }

  loadMore = (page: number) => this.fetchPosts(this.props.tagId, page);
  loadRest = () => this.fetchPosts(this.props.tagId, this.props.page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      isTagLoaded,
      isTagLoading,
      showCreatePostAction,
      layoutType,
      showPagination,
      PostListProGallery,
      canSeeMoreButton,
      page,
      pageSize,
    } = this.props;

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={this.state.nextPage}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isTagLoading && !isTagLoaded}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={this.state.nextPage}
        layoutType={layoutType}
        entityCount={entityCount}
        page={page}
        pageSize={pageSize}
        loadRest={showPagination ? this.loadRest : undefined}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isTagLoading && !isTagLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  createPageUrl = (page: number) => createPageUrl(page, this.props.tagUrl);

  renderPagination() {
    const { entityCount, pageSize, page, isSite, tagPath, showPagination } = this.props;

    return showPagination ? (
      <Pagination
        dataHook="tags-pagination-container"
        page={page}
        pageSize={pageSize}
        entityCount={entityCount}
        createPageUrl={this.createPageUrl}
        handleNavigation={handleNavigation(tagPath, isSite)}
      />
    ) : null;
  }

  render() {
    const { isTagLoaded, isTagLoading } = this.props;

    const isLoading = isTagLoading && !isTagLoaded;

    return (
      <Page noSpacing>
        <Layout>
          <AnimatedLoader isLoading={isLoading}>{this.renderPosts()}</AnimatedLoader>
          {!isLoading && this.renderPagination()}
          {isTagLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

const connector = connect((state, ownProps: OwnProps, actions) => {
  const tagSlug = get(ownProps, ['params', 'tagSlug']);
  const tag = getTagBySlug(state, tagSlug);
  const tagId = tag?.id;

  const sectionUrl = getSectionUrl(state);
  const tagUrl = getTagUrl(sectionUrl, tagSlug);

  const showCreatePostAction: boolean =
    getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);

  return {
    tagId,
    tagPath: 'tags',
    tagUrl,
    allPosts: tagId ? getSortedPostsByTagId(state, tagId) : [],
    currentPagePosts: tagId ? getPostsByTagIdAndPage(state, tagId, ownProps.page) : [],
    isTagLoading: getIsTagLoading(state, tagId),
    isTagLoaded: getIsTagLoaded(state, tagId),
    isSite: isSiteSelector(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    setIsLoaded: actions.setIsLoaded,
    fetchTagPosts: (params: { page: number; tagId: string }) => {
      return actions.fetchTagPostsWithAdapterPromisified({
        page: params.page,
        includeContent: isPostContentRequired(state),
        tagOptions: { id: params.tagId },
      });
    },
    canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
  };
});

export default flowRight(
  withComponents,
  withPermissions,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withTranslate,
  connector,
)(TagsPage);
