import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimatedLoader from '../../../common/components/animated-loader';
import { connect } from '../../../common/components/runtime-context';
import { isSeo } from '../../../common/store/basic-params/basic-params-selectors';
// import { getCount } from '../../../common/selectors/location-history-selectors';

const isServer = typeof window === 'undefined';

class MasonryWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContentVisible:
        !props.isEnabled || (!isServer && props.historyEntryCount > 1) || props.isSeo,
    };
  }

  componentDidMount() {
    this.setState({ isContentVisible: true });
  }

  render() {
    return (
      <AnimatedLoader shouldShowLoader={false} isLoading={!this.state.isContentVisible}>
        {this.props.children}
      </AnimatedLoader>
    );
  }
}

MasonryWrapper.propTypes = {
  historyEntryCount: PropTypes.number,
  children: PropTypes.node,
  isEnabled: PropTypes.bool,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  // historyEntryCount: getCount(state), // TODO
  isSeo: isSeo(state),
});

export default connect(mapRuntimeToProps)(MasonryWrapper);
