import React from 'react';
import classNames from 'classnames';
import {
  isLayoutPGSideBySide,
  isLayoutPGOneColumn,
  isLayoutFullPost,
  isLayoutProGallery,
  getIsAppBundle,
  type LayoutSettingsType,
} from '@wix/communities-blog-client-common';
import useLayoutProps from '../../hooks/use-layout-props';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getLayoutPostSize,
} from '../../selectors/app-settings-selectors';
import { isUsingResponsiveStyling } from '../../services/post-list-pro-gallery';
import { useResponsiveContext } from '../responsive-listener/responsive-context';
import { useSelector } from '../runtime-context';
import styles from './desktop-header.scss';

type Props = {
  /** Provided by `app-desktop`  */
  menu?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
};

const DesktopHeader: React.FC<Props> = ({ left, right, menu, className }) => {
  const layoutMargins = useSelector((state) => getLayoutMargins({ state }));
  const isAppBundle = useSelector(getIsAppBundle);
  const createdWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const { section, layoutType } = useLayoutProps();
  const useResponsiveEditorStyling = isUsingResponsiveStyling({
    createdWithResponsiveEditor,
    section,
  });
  const { rootWidth } = useResponsiveContext();
  const layoutPostSize = useSelector((state, host) => {
    return getLayoutPostSize({
      state,
      section,
      layoutType: layoutType as LayoutSettingsType,
      hostWidth: host.dimensions.width,
      rootWidth,
      layoutMargins: useResponsiveEditorStyling ? undefined : layoutMargins,
    });
  });

  const containerClassName = classNames(
    styles.container,
    className,
    'blog-header-background-color',
  );
  const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
  const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);
  const layoutFullPost = isLayoutFullPost(layoutType);

  const wrapperClassName = classNames(
    styles.header,
    !isLayoutProGallery(layoutType!) && styles.notPGLayout,
    isAppBundle &&
      (!isLayoutProGallery(layoutType!) || useResponsiveEditorStyling) &&
      styles.withPadding,
  );

  const containerStyles =
    useResponsiveEditorStyling || layoutPGSideBySide || layoutPGOneColumn || !layoutMargins
      ? {}
      : { paddingRight: layoutMargins, paddingLeft: layoutMargins };

  const wrapperStyles = {
    ...((layoutPGSideBySide || layoutPGOneColumn) && {
      maxWidth: layoutPostSize,
    }),
    ...(useResponsiveEditorStyling && layoutFullPost && { maxWidth: 940 }),
  };

  return (
    <div
      style={containerStyles}
      className={containerClassName}
      data-hook="blog-desktop-header-container"
    >
      <div style={wrapperStyles} className={wrapperClassName}>
        {left}
        {!createdWithResponsiveEditor && right}
      </div>
      {menu && <div className={styles.menu}>{menu}</div>}
    </div>
  );
};

export default DesktopHeader;
